import React from 'react';
import TextEditor from "../components/TextEditor";

// TODO: rédiger les mentions légales

const MentionsLegales = () => {
    return (
        <>
        <div className="container mx-auto">
            <h1 className="font-playfair-display font-bold text-7xl">Mentions Légales</h1>
        </div>
            <div className="container mx-auto">
                <TextEditor />
            </div>
        </>
    );
};

export default MentionsLegales;