import React from "react"
import { motion } from "framer-motion"

const TextEditor = () => {
  return (
    <div className="mx-auto max-w-6xl py-24">
      <div className="overflow-hidden rounded border border-[#B0B0B0]">
        <div className="bg-[#4A4A4A]">
          <tabnav className="block px-2 pt-2">
            <div className="flex">
              <tabHeader className="-mb-[1px] block rounded-t border border-b-0 border-[#B0B0B0] bg-eido-grey p-2 text-white">
                message.js
              </tabHeader>
            </div>
          </tabnav>
          <div className="panel bg-eido-grey p-2 border-t border-[#B0B0B0]">
            <div className="flex">
              <div className="text-right">
                {[...Array(14)].map((x, i) => (
                  <div className="pr-2 text-white text-opacity-50">{i}</div>
                ))}
              </div>
              <div className="flex-1 text-white">
                <pre># bash</pre>
                <pre> </pre>
                <pre
                  dangerouslySetInnerHTML={{
                    __html:
                      'import { <span class"text-pink-500">sendMessage</span> } as lol',
                  }}
                />
                <pre><span className="text-pink-400">function </span>envoyerMessage</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextEditor
